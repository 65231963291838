import React from "react"
import Privacy from "../../../components/body/pages/en-ng/legal/privacy"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/privacy-policy"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <Privacy />
  </Layout>
)

export default PrivacyPage
